import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import TeamMemberCard from './TeamMemberCard';

const StyledTeamSection = styled.section`
  margin: 0 auto;

  padding-top: 30px;
  padding-bottom: 20px;
  background: var(--white);
  width: 90%;
  max-width: 512px;
  @media only screen and (min-width: 884px) {
    padding: 5rem 0;
    max-width: 788px;
  }
  transform: translatey(-50px);
  @media only screen and (min-width: 760px) {
    transform: translatey(-100px);
  }

  box-shadow: 0.1em 0.1em 0.5em rgba(60, 60, 60, 65%);
  h2 {
    font-size: 2.4rem;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }
  .text-container,
  .team-container {
    padding-left: 20px;
    padding-right: 20px;
    @media only screen and (min-width: 884px) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  .team-container {
    text-align: center;
    display: grid;
    justify-items: center;
    gap: 20px;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
`;

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
    },
  },
};

const MotionTeamSection = motion(StyledTeamSection);

export default function TeamSection({ teamData, aboutText }) {
  const teams = teamData.map((teamMember) => {
    return <TeamMemberCard teamMember={teamMember} />;
  });
  return (
    <MotionTeamSection
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
    >
      <motion.div variants={itemVariants} className="text-container">
        {aboutText}
      </motion.div>
      <motion.h2 variants={itemVariants}>Our Team</motion.h2>
      <div className="team-container">{teams}</div>
    </MotionTeamSection>
  );
}
