import React from 'react';
import { graphql } from 'gatsby';
import Hero from '../components/Hero';
import styled from 'styled-components';
import TeamSection from '../components/TeamSection';
import SEO from '../components/SEO';

const AboutPageBackground = styled.div`
  background: var(--charcoal);
  position: relative;
  min-height: calc(100vh - 184px);
`;

export default function AboutPage({ data, location }) {
  return (
    <>
      <SEO title={'About SMS Inc.'} />
      <AboutPageBackground>
        <Hero
          bg={data.sanityAboutPage}
          heading={data.sanityAboutPage.name}
          subText={''}
          bgAlt={data.sanityAboutPage.headerImage.altText}
          location={location}
        />
        <TeamSection
          teamData={data.sanityAboutPage.teamMembers}
          aboutText={data.sanityAboutPage.description}
        />
      </AboutPageBackground>
    </>
  );
}

export const { data } = graphql`
  query AboutPageQuery {
    sanityAboutPage(id: { eq: "-28691025-906e-5633-8567-9a7dab6990af" }) {
      galleryImages {
        asset {
          gatsbyImageData
        }
        altText
      }
      headerImage {
        asset {
          gatsbyImageData(formats: [AUTO, WEBP, AVIF], aspectRatio: 1.7778)
        }
        altText
      }
      teamMembers {
        image {
          asset {
            gatsbyImageData
          }
        }
        name
        position
      }
      description
      name
    }
  }
`;
