import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

const StyledHero = styled.section`
  display: grid;
  min-height: 376px;
  max-height: 800px;
  @media (min-width: 1024px) {
    height: 650px;
    &.fabrication {
      height: 580px;
    }
  }
  .background-image {
    grid-area: 1 / 1;
    max-height: 800px;
  }
  .filter {
    grid-area: 1 / 1;
    position: relative;
    background: rgba(0, 0, 0, 0.35);
  }
  .text-container {
    grid-area: 1 / 1;
    position: relative;
    place-items: center;
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-bottom: 75px;
    h1 {
      padding-top: 40px;
      margin-left: 5%;
      margin-right: 5%;
      margin-bottom: 36px;
      text-align: center;
      @media (min-width: 1024px) {
        margin-bottom: 48px;
      }
    }
    p {
      margin: 0 5%;
      @media (min-width: 1024px) {
        width: 720px;
      }
    }
  }
  &.contact {
    height: 300px;
    .text-container {
      height: 285px;
    }
  }
  &.about {
    .text-container {
      padding-bottom: 200px;
      @media screen and (min-width: 960px) {
        padding-bottom: 0;
      }
    }
  }
`;
export default function Hero({ heading, subText, bg, bgAlt, location }) {
  const image = getImage(bg.headerImage.asset.gatsbyImageData);
  const pathString = location.pathname;
  const pathClass = pathString.substring(1, pathString.length);
  const MotionHero = motion(StyledHero);
  const textContainer = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        staggerChildren: 0.5,
        delayChildren: 0.4,
      },
    },
    exit: { opacity: 0 },
  };
  const itemVariants = {
    offscreen: {
      y: 300,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        ease: [0.19, 1, 0.22, 1],
        duration: 1.5,
      },
    },
  };

  const textItems = {
    initial: {
      y: '300',
      transition: {
        y: { ease: [0.19, 1, 0.22, 1], duration: 1.5 },
      },
    },
    animate: {
      y: '0',
      transition: {
        y: { ease: [0.19, 1, 0.22, 1], duration: 1.5 },
      },
    },
  };
  return (
    <MotionHero
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.25 }}
      variants={itemVariants}
      className={pathClass}
    >
      <GatsbyImage
        className="background-image"
        alt={bgAlt || 'background image'}
        loading="eager"
        image={image}
      />
      <div className="filter" />
      <motion.div
        variants={textContainer}
        transition={{ delay: 0.5 }}
        className="text-container"
      >
        <motion.h1
          initial={{ y: 400 }}
          animate={{ y: 0 }}
          transition={{
            ease: [0.19, 1, 0.22, 1],
            duration: 1.5,
          }}
        >
          {heading}
        </motion.h1>
        <motion.p
          initial={{ y: 400 }}
          animate={{ y: 0 }}
          transition={{
            ease: [0.19, 1, 0.22, 1],
            duration: 1.5,
            delay: 0.1,
          }}
        >
          {subText}
        </motion.p>
      </motion.div>
    </MotionHero>
  );
}
