import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledCard = styled.div`
  position: relative;
  margin: 0 auto;
  @media screen and (min-width: 417px) {
    width: 340px;
  }
  .card-box {
    margin: 0 auto;
    box-shadow: 0.1em 0.1em 0.5em rgba(60, 60, 60, 65%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--lightgrey);
    @media screen and (min-width: 417px) {
      flex-direction: row;
    }
    .image-container {
      width: 100%;
      height: 190px;
    }
    .card-text {
      padding: 24px;
      justify-self: center;
      h3,
      .subheading {
        font-family: Poppins;
        font-weight: normal;
      }
      h3 {
        font-size: 2.25rem;
      }
      .subheading {
        font-size: 1.75rem;
      }
    }
  }
`;

const MotionImage = motion(GatsbyImage);
const MotionCard = motion(StyledCard);

const itemVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.2,
    },
  },
};
const imageVariants = {
  offscreen: {
    y: 300,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.19, 1, 0.22, 1],
      duration: 1.5,
      delay: 0.1,
    },
  },
};

export default function TeamMemberCard({ teamMember }) {
  const image = getImage(teamMember.image.asset.gatsbyImageData);
  return (
    <MotionCard
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.25 }}
    >
      <motion.div variants={itemVariants} className="card-box">
        <GatsbyImage
          image={image}
          alt={`Photo of ${teamMember.name}, ${teamMember.position} at SMS`}
        />
        <motion.div variants={itemVariants} className="card-text">
          <motion.h3 variants={itemVariants}>{teamMember.name}</motion.h3>
          <motion.p variants={itemVariants} className="subheading">
            {teamMember.position}
          </motion.p>
        </motion.div>
      </motion.div>
    </MotionCard>
  );
}
